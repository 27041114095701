import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';

import Splash from 'components/Splash';
import MainLayout from 'components/MainLayout';

const PageNotFoundView = React.lazy(() => import('./PageNotFoundView'));
const Home = React.lazy(() => import('./Home'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const TermsOfUse = React.lazy(() => import('./TermsOfUse'));

const RouteLoader: React.FC = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Flex w="100%" h="100%" justifyContent={'center'} alignItems={'center'}>
          <Spinner size={'lg'} />
        </Flex>
      }>
      {children}
    </React.Suspense>
  );
};

const PageRoutes = () => {
  const isInitializing = false;

  const mainRoutes: RouteObject = {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: '/',
        element: (
          <RouteLoader>
            <Home />
          </RouteLoader>
        ),
      },
      {
        path: '/privacy-policy',
        element: (
          <RouteLoader>
            <PrivacyPolicy />
          </RouteLoader>
        ),
      },
      {
        path: '/terms-of-use',
        element: (
          <RouteLoader>
            <TermsOfUse />
          </RouteLoader>
        ),
      },
    ],
  };

  const NotFoundRoutes: RouteObject = {
    path: '404',
    element: <PageNotFoundView />,
  };

  const routing = useRoutes([NotFoundRoutes, mainRoutes]);

  if (isInitializing) {
    return <Splash />;
  }

  return <>{routing}</>;
};

export default PageRoutes;
