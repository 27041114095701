import React from 'react';
import { chakra, HTMLChakraProps } from '@chakra-ui/react';

interface LogoProps extends HTMLChakraProps<'svg'> {}

const Logo: React.FC<LogoProps> = ({ ...rest }) => {
  const color = '#3C3D35';
  const iconColor = '#4532CC';

  return (
    <chakra.svg viewBox="0 0 143 26" fill="none" {...rest}>
      <path
        d="M45.3591 22.2395C45.0612 22.2395 44.8104 22.1278 44.6065 21.9043C44.4027 21.6968 44.3008 21.4415 44.3008 21.1382V5.3602C44.3008 5.05693 44.4027 4.80155 44.6065 4.59405C44.8104 4.38655 45.0612 4.2828 45.3591 4.2828C45.657 4.2828 45.9079 4.38655 46.1117 4.59405C46.3312 4.80155 46.441 5.05693 46.441 5.3602V5.9827C47.5856 4.76963 48.9262 4.16309 50.4627 4.16309C51.4348 4.16309 52.3521 4.41847 53.2144 4.92924C54.0925 5.42405 54.798 6.15828 55.3311 7.13193C55.8799 8.08963 56.1543 9.21492 56.1543 10.5078C56.1543 11.8007 55.8799 12.934 55.3311 13.9076C54.798 14.8653 54.0925 15.5995 53.2144 16.1103C52.3521 16.6051 51.4348 16.8525 50.4627 16.8525C48.9262 16.8525 47.5856 16.246 46.441 15.0329V21.1382C46.441 21.4574 46.3391 21.7208 46.1353 21.9283C45.9314 22.1358 45.6727 22.2395 45.3591 22.2395ZM50.157 14.8174C51.3172 14.8174 52.2502 14.4423 52.9557 13.6921C53.6613 12.9419 54.0141 11.8805 54.0141 10.5078C54.0141 9.13511 53.6613 8.07367 52.9557 7.32347C52.2502 6.57328 51.3172 6.19818 50.157 6.19818C49.4357 6.19818 48.7537 6.36578 48.1108 6.70097C47.4837 7.0202 46.9271 7.45117 46.441 7.99386V13.0217C46.9271 13.5644 47.4837 14.0034 48.1108 14.3386C48.7537 14.6578 49.4357 14.8174 50.157 14.8174Z"
        fill={color}
      />
      <path
        d="M62.1953 16.8525C61.5055 16.8525 60.8626 16.7089 60.2668 16.4216C59.6867 16.1342 59.2241 15.7192 58.8792 15.1766C58.5342 14.6179 58.3618 13.9794 58.3618 13.2612C58.3618 12.08 58.8165 11.1383 59.7259 10.436C60.6509 9.73367 61.9993 9.38251 63.7711 9.38251H66.6169V9.19097C66.6169 8.13751 66.3582 7.37934 65.8408 6.91645C65.339 6.45357 64.5472 6.22213 63.4654 6.22213C62.8695 6.22213 62.3365 6.27001 61.8661 6.36578C61.4114 6.46155 60.9018 6.6052 60.3373 6.79674C60.2119 6.82866 60.11 6.84463 60.0316 6.84463C59.7964 6.84463 59.5926 6.75684 59.4201 6.58126C59.2476 6.40568 59.1614 6.1902 59.1614 5.93482C59.1614 5.50386 59.3652 5.20059 59.7729 5.02501C61.1056 4.45039 62.4619 4.16309 63.8417 4.16309C64.9235 4.16309 65.8329 4.38655 66.5699 4.83347C67.3225 5.2804 67.8712 5.86299 68.2162 6.58126C68.5768 7.28357 68.7571 8.04972 68.7571 8.87972V15.6554C68.7571 15.9587 68.6474 16.2141 68.4278 16.4216C68.224 16.6291 67.9731 16.7328 67.6752 16.7328C67.3773 16.7328 67.1265 16.6291 66.9226 16.4216C66.7188 16.2141 66.6169 15.9587 66.6169 15.6554V15.0329C65.4566 16.246 63.9828 16.8525 62.1953 16.8525ZM62.995 14.9371C63.6849 14.9371 64.3512 14.7695 64.9941 14.4343C65.6369 14.0832 66.1779 13.6522 66.6169 13.1415V11.1303H64.0533C61.6858 11.1303 60.502 11.7608 60.502 13.0217C60.502 13.5964 60.6901 14.0592 61.0664 14.4104C61.4584 14.7616 62.1013 14.9371 62.995 14.9371Z"
        fill={color}
      />
      <path
        d="M74.8187 22.2395C74.5365 22.2395 74.2935 22.1358 74.0896 21.9283C73.9015 21.7208 73.8074 21.4734 73.8074 21.1861C73.8074 21.0584 73.8309 20.9307 73.878 20.803L75.4067 16.8046L70.9852 5.83905C70.9224 5.67943 70.8911 5.5278 70.8911 5.38415C70.8911 5.08088 70.993 4.82549 71.1968 4.61799C71.4007 4.39453 71.6515 4.2828 71.9494 4.2828C72.1689 4.2828 72.3649 4.34664 72.5374 4.47434C72.7255 4.60203 72.8588 4.76165 72.9372 4.95318L76.5591 14.0991L80.2516 5.00107C80.4554 4.52222 80.7847 4.2828 81.2394 4.2828C81.5373 4.2828 81.7882 4.39453 81.992 4.61799C82.1958 4.82549 82.2977 5.08088 82.2977 5.38415C82.2977 5.5278 82.2742 5.66347 82.2272 5.79116L75.8065 21.4973C75.587 21.9921 75.2578 22.2395 74.8187 22.2395Z"
        fill={color}
      />
      <path
        d="M88.3447 16.7328C88.0311 16.7328 87.7488 16.637 87.498 16.4455C87.2471 16.254 87.0746 16.0145 86.9806 15.7272L83.6409 5.76722C83.5938 5.62357 83.5703 5.4879 83.5703 5.3602C83.5703 5.05693 83.6722 4.80155 83.8761 4.59405C84.0799 4.38655 84.3308 4.2828 84.6287 4.2828C84.8482 4.2828 85.0442 4.34664 85.2166 4.47434C85.4048 4.58607 85.5302 4.7377 85.5929 4.92924L88.3447 14.147L91.0728 5.14472C91.1512 4.88934 91.2924 4.68184 91.4962 4.52222C91.7157 4.36261 91.9587 4.2828 92.2253 4.2828C92.4918 4.2828 92.727 4.36261 92.9308 4.52222C93.1503 4.68184 93.2993 4.88934 93.3777 5.14472L96.1059 14.147L98.8576 4.92924C98.9203 4.7377 99.0379 4.58607 99.2104 4.47434C99.3985 4.34664 99.6024 4.2828 99.8219 4.2828C100.12 4.2828 100.371 4.38655 100.574 4.59405C100.778 4.80155 100.88 5.05693 100.88 5.3602C100.88 5.4879 100.857 5.62357 100.81 5.76722L97.47 15.7272C97.3759 16.0145 97.2034 16.254 96.9526 16.4455C96.7017 16.637 96.4195 16.7328 96.1059 16.7328C95.7923 16.7328 95.5101 16.637 95.2592 16.4455C95.0083 16.254 94.828 16.0066 94.7183 15.7033L92.2253 7.77838L89.7323 15.7033C89.6225 16.0066 89.4422 16.254 89.1913 16.4455C88.9405 16.637 88.6582 16.7328 88.3447 16.7328Z"
        fill={color}
      />
      <path
        d="M108.528 16.8525C107.32 16.8525 106.262 16.5892 105.352 16.0624C104.443 15.5357 103.738 14.7935 103.236 13.8358C102.75 12.8621 102.507 11.7528 102.507 10.5078C102.507 9.2628 102.75 8.16145 103.236 7.20376C103.738 6.23011 104.443 5.47991 105.352 4.95318C106.262 4.42645 107.32 4.16309 108.528 4.16309C109.719 4.16309 110.77 4.42645 111.679 4.95318C112.588 5.47991 113.294 6.23011 113.796 7.20376C114.297 8.16145 114.548 9.2628 114.548 10.5078C114.548 11.7528 114.297 12.8621 113.796 13.8358C113.294 14.7935 112.588 15.5357 111.679 16.0624C110.77 16.5892 109.719 16.8525 108.528 16.8525ZM108.528 14.8653C109.17 14.8653 109.782 14.7137 110.362 14.4104C110.958 14.1071 111.444 13.6283 111.82 12.9739C112.212 12.3194 112.408 11.4894 112.408 10.4839C112.408 9.49424 112.212 8.67222 111.82 8.0178C111.444 7.36338 110.958 6.89251 110.362 6.6052C109.782 6.30193 109.17 6.1503 108.528 6.1503C107.869 6.1503 107.242 6.30193 106.646 6.6052C106.066 6.89251 105.588 7.36338 105.211 8.0178C104.835 8.67222 104.647 9.49424 104.647 10.4839C104.647 11.4894 104.835 12.3194 105.211 12.9739C105.588 13.6283 106.066 14.1071 106.646 14.4104C107.242 14.7137 107.869 14.8653 108.528 14.8653Z"
        fill={color}
      />
      <path
        d="M118.603 16.7328C118.305 16.7328 118.054 16.6291 117.85 16.4216C117.647 16.2141 117.545 15.9587 117.545 15.6554V5.38415C117.545 5.08088 117.647 4.82549 117.85 4.61799C118.054 4.39453 118.305 4.2828 118.603 4.2828C118.901 4.2828 119.152 4.39453 119.356 4.61799C119.575 4.82549 119.685 5.08088 119.685 5.38415V6.36578C120.281 5.69539 120.924 5.16068 121.613 4.76164C122.303 4.36261 123.119 4.16309 124.059 4.16309C124.922 4.16309 125.666 4.37059 126.294 4.78559C126.937 5.20059 127.423 5.76722 127.752 6.48549C128.097 7.20376 128.269 7.99386 128.269 8.85578V15.6554C128.269 15.9587 128.159 16.2141 127.94 16.4216C127.736 16.6291 127.485 16.7328 127.187 16.7328C126.889 16.7328 126.639 16.6291 126.435 16.4216C126.231 16.2141 126.129 15.9587 126.129 15.6554V9.09521C126.129 7.1479 125.267 6.17424 123.542 6.17424C122.758 6.17424 122.052 6.38174 121.425 6.79674C120.814 7.21174 120.234 7.7704 119.685 8.4727V15.6554C119.685 15.9587 119.575 16.2141 119.356 16.4216C119.152 16.6291 118.901 16.7328 118.603 16.7328Z"
        fill={color}
      />
      <path
        d="M137.474 22.3593C136.565 22.3593 135.647 22.2395 134.722 22.0001C133.797 21.7607 133.037 21.4654 132.441 21.1143C132.159 20.9387 132.018 20.6593 132.018 20.2763C132.018 20.0209 132.104 19.7894 132.276 19.5819C132.464 19.3904 132.692 19.2946 132.958 19.2946C133.084 19.2946 133.225 19.3266 133.382 19.3904C134.573 19.9969 135.867 20.3002 137.262 20.3002C139.661 20.3002 140.861 18.9993 140.861 16.3976V14.7935C139.747 16.0066 138.407 16.6131 136.839 16.6131C135.914 16.6131 135.012 16.3817 134.134 15.9188C133.272 15.4399 132.558 14.7376 131.994 13.8118C131.43 12.8701 131.147 11.7289 131.147 10.3881C131.147 9.04732 131.43 7.91405 131.994 6.98828C132.558 6.04655 133.272 5.34424 134.134 4.88136C135.012 4.40251 135.914 4.16309 136.839 4.16309C138.407 4.16309 139.747 4.76963 140.861 5.9827V5.38415C140.861 5.08088 140.963 4.82549 141.166 4.61799C141.37 4.39453 141.621 4.2828 141.919 4.2828C142.217 4.2828 142.468 4.39453 142.672 4.61799C142.891 4.82549 143.001 5.08088 143.001 5.38415V16.0864C143.001 18.0975 142.523 19.6458 141.566 20.7312C140.61 21.8166 139.246 22.3593 137.474 22.3593ZM137.286 14.6259C138.023 14.6259 138.673 14.4663 139.238 14.147C139.802 13.8278 140.343 13.3968 140.861 12.8541V7.92203C140.343 7.37934 139.802 6.94838 139.238 6.62915C138.673 6.30991 138.023 6.1503 137.286 6.1503C136.11 6.1503 135.146 6.51741 134.393 7.25165C133.656 7.96992 133.288 9.0154 133.288 10.3881C133.288 11.7608 133.656 12.8142 134.393 13.5485C135.146 14.2667 136.11 14.6259 137.286 14.6259Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5577 1.39165C29.2033 0.351513 26.4776 1.42816 25.4696 3.79642L19.2382 18.4363C18.2302 20.8045 19.3216 23.5676 21.6759 24.6077C24.0302 25.6478 26.756 24.5712 27.764 22.2029L33.9953 7.56309C35.0034 5.19484 33.912 2.4318 31.5577 1.39165ZM13.5152 3.79643C14.5232 1.42817 17.249 0.351523 19.6033 1.39166C21.9576 2.43181 23.049 5.19485 22.041 7.5631L15.8096 22.2029C14.8016 24.5712 12.0759 25.6478 9.72155 24.6077C7.36723 23.5676 6.27584 20.8045 7.28387 18.4363L13.5152 3.79643ZM9.74731 8.31507L9.2119 9.56225L8.8077 10.5038L5.81769 17.4688C5.49445 18.2217 4.40696 18.1857 4.117 17.4117L0.829992 9.19103L0.447948 8.23651C-0.114292 6.97685 -0.16872 5.52246 0.407065 4.18122C1.50813 1.6164 4.48615 0.47667 7.07391 1.62974C9.63792 2.77224 10.8383 5.77377 9.74731 8.31507Z"
        fill={iconColor}
      />
    </chakra.svg>
  );
};

export default Logo;
