import React from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

import MotionBox from './MotionBox';

import Logo from 'assets/Logo';
import { HiOutlineMenu } from 'react-icons/hi';
import { FeatureProps } from 'constants/types';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'home']);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const features: FeatureProps[] = [
    {
      title: t('common:home'),
    },
    {
      title: t('common:features'),
      to: 'features',
    },
    {
      title: t('common:guide'),
      to: 'guide',
    },
    {
      title: t('common:pricing'),
      to: 'pricing',
      offset: -150,
    },
    // {
    //   title: t('common:blog'),
    //   to: 'blog',
    // },
  ];

  const handleLogin = React.useCallback(() => {
    const url = 'https://app.paywong.com/auth/login';
    window.open(url, '_blank', 'noreferrer');
  }, []);

  return (
    <Box bg="white" h="64px">
      <MotionBox
        display="flex"
        alignItems="center"
        h="full"
        justifyContent="space-between"
        px={{ base: '4', md: '8' }}>
        <MotionBox
          whileHover={{ scale: 0.97 }}
          cursor="pointer"
          onClick={() => navigate('/')}
          initial={{ translateX: '-200%' }}
          animate={{
            translateX: 0,
            transition: {
              duration: 1,
            },
          }}>
          <Logo h={{ base: '6', md: '8' }} />
        </MotionBox>
        <MotionBox
          initial={{ translateX: '200%', opacity: 0 }}
          animate={{
            opacity: 1,
            translateX: 0,
            transition: {
              duration: 1.5,
            },
          }}>
          <HStack spacing="8" display={{ base: 'none', md: 'flex' }}>
            {features.map((item, index) => {
              const { title, to, offset, onClick } = item;
              return (
                <Link
                  key={index}
                  offset={offset}
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => {
                    onClick && onClick();
                    navigate('/');
                  }}
                  to={to ? to : ''}>
                  <Box cursor="pointer" fontSize="sm" color="primary" fontWeight="semibold">
                    {title}
                  </Box>
                </Link>
              );
            })}
            <Button children={t('home:login')} onClick={handleLogin} />
          </HStack>
        </MotionBox>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="unstyled"
          cursor="pointer"
          aria-label="Menu"
          icon={<HiOutlineMenu fontSize="1.5rem" />}
          color="primary"
        />
        <Drawer
          size="xs"
          placement="left"
          isOpen={isOpen}
          blockScrollOnMount={false}
          onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg="yellow" shadow="none" position="relative" maxW="64">
            <VStack spacing="6" py="6">
              {features.map((item, index) => {
                const { title, to, offset, onClick } = item;
                return (
                  <Link
                    key={index}
                    to={to ? to : ''}
                    offset={offset}
                    spy={true}
                    smooth={true}
                    duration={1000}
                    onClick={() => {
                      onClose();
                      onClick && onClick();
                      navigate('/');
                    }}>
                    <Box cursor="pointer" fontSize="sm" color="primary" fontWeight="semibold">
                      {title}
                    </Box>
                  </Link>
                );
              })}
              <Button children={t('home:login')} onClick={handleLogin} />
            </VStack>
            <DrawerCloseButton
              bg="primary"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              rounded="0"
              position="absolute"
              color="white"
              right="-8"
              top="0"
            />
          </DrawerContent>
        </Drawer>
      </MotionBox>
    </Box>
  );
};

export default Header;
