import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import Header from './Header';
import Footer from './Footer';

const MainLayout: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" flex="1" overflow="hidden" minH="100vh">
        <Header />
        <Flex flex="1" bg="white">
          <Outlet />
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};

export default MainLayout;
