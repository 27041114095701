import React from 'react';
import Icon from '@chakra-ui/icon';
import { SvgIconProps } from 'constants/types';

const SvgComponent: React.FC<SvgIconProps> = ({ size, ...props }: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        d="M12.2233 12.226H10.4467V9.44133C10.4467 8.77733 10.4333 7.92267 9.52 7.92267C8.594 7.92267 8.45267 8.64533 8.45267 9.39267V12.226H6.67533V6.5H8.382V7.28H8.40533C8.644 6.83067 9.224 6.35533 10.0907 6.35533C11.8907 6.35533 12.224 7.54067 12.224 9.08267V12.226H12.2233ZM4.66867 5.71667C4.53304 5.71684 4.39871 5.69025 4.27338 5.6384C4.14804 5.58656 4.03418 5.51049 3.9383 5.41456C3.84243 5.31862 3.76644 5.20471 3.71467 5.07934C3.66291 4.95398 3.6364 4.81963 3.63667 4.684C3.6368 4.47989 3.69745 4.2804 3.81096 4.11076C3.92447 3.94113 4.08573 3.80896 4.27435 3.73097C4.46298 3.65298 4.67049 3.63268 4.87065 3.67263C5.07082 3.71258 5.25464 3.81098 5.39887 3.9554C5.54311 4.09983 5.64128 4.28377 5.68097 4.48399C5.72066 4.6842 5.70009 4.89169 5.62185 5.08021C5.54362 5.26873 5.41125 5.42983 5.24146 5.54311C5.07168 5.6564 4.87211 5.7168 4.668 5.71667H4.66867ZM5.55933 12.226H3.77733V6.5H5.56V12.226H5.55933ZM13.1133 2H2.886C2.39533 2 2 2.38667 2 2.86467V13.1353C2 13.6133 2.396 14 2.88533 14H13.1107C13.6 14 14 13.6133 14 13.1353V2.86467C14 2.38667 13.6 2 13.1107 2H13.1127H13.1133Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconLinkedIn = React.memo(SvgComponent);
export default IconLinkedIn;
