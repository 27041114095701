const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: any) => ({
      bg: 'primary',
      color: 'white',
      _hover: {
        transform: 'auto',
        translateY: '-2px',
      },
    }),
    secondary: (props: any) => ({
      bg: 'white',
      color: 'black',
      _hover: {
        transform: 'auto',
        translateY: '-2px',
      },
      shadow: 'md',
    }),
    third: (props: any) => ({
      bg: 'white',
      color: 'primary',
      _hover: {
        transform: 'auto',
        translateY: '-2px',
      },
    }),
  },
  defaultProps: {},
};

export { Button };
