import { extendTheme, withDefaultColorScheme, ThemeConfig } from '@chakra-ui/react';
import { Button, Input } from './components';

const config: ThemeConfig = {
  initialColorMode: 'system', // light, dark or system
  useSystemColorMode: false,
};

export const customTheme = extendTheme(
  {
    config,
    colors: {
      primary: '#4532CC',
      yellow: '#FFDF44',
      black: '#3C3D35',
    },
    semanticTokens: {
      colors: {
        error: 'red.500',
        'text-title': {
          default: 'black',
          _dark: 'white',
        },
        'text-body': {
          default: 'gray.800',
          _dark: 'gray.50',
        },
        'text-note': {
          default: 'gray.600',
          _dark: 'gray.300',
        },
        'text-info': {
          default: 'gray.700',
          _dark: 'gray.200',
        },
        'text-placeholder': {
          default: 'gray.400',
          _dark: 'gray.500',
        },
        'text-sub': {
          default: 'gray.500',
          _dark: 'gray.400',
        },
        'text-link-hover': {
          default: 'gray.900',
          _dark: 'white',
        },
        'text-token': {
          default: 'blackAlpha.500',
          _dark: 'blackAlpha.500',
        },
        'text-description': {
          default: 'blackAlpha.900',
          _dark: 'whiteAlpha.900',
        },
        'bg-main': {
          default: 'white',
          _dark: 'gray.900',
        },
        'bg-content': {
          default: 'gray.50',
          _dark: 'black',
        },
        'bg-border': {
          default: 'gray.200',
          _dark: 'gray.700',
        },
        'bg-border-content': {
          default: 'gray.100',
          _dark: 'gray.600',
        },
        'bg-link-active': {
          default: 'blue.50',
          _dark: 'blue.800',
        },
      },
    },
    components: {
      Alert: {
        defaultProps: {
          colorScheme: 'primary',
        },
      },
      Button,
      Input,
    },
    fonts: {
      heading: 'Rubik',
      body: 'Rubik',
      mono: 'Rubik',
    },
  },
  withDefaultColorScheme({ colorScheme: 'primary' })
);
