import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ms';
import common from './en/common.json';
import home from './en/home.json';

export const defaultNS = 'common';

export const resources = {
  en: {
    common,
    home,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['common', 'home'],
  defaultNS,
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng);
});
