import React from 'react';
import Icon from '@chakra-ui/icon';
import { SvgIconProps } from 'constants/types';

const SvgComponent: React.FC<SvgIconProps> = ({ size, ...props }: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        d="M14.7762 3.77062C14.2672 3.99575 13.7275 4.1436 13.1748 4.20928C13.7573 3.86091 14.1933 3.31263 14.4015 2.66662C13.8548 2.99195 13.2555 3.21995 12.6308 3.34328C12.2112 2.89434 11.6551 2.59659 11.0488 2.49634C10.4425 2.39609 9.82013 2.49895 9.27834 2.78892C8.73656 3.07889 8.30576 3.53974 8.05291 4.09981C7.80006 4.65989 7.73933 5.2878 7.88017 5.88595C6.77157 5.83039 5.68705 5.5423 4.69702 5.04039C3.70699 4.53849 2.83359 3.83398 2.1335 2.97262C1.88569 3.39825 1.75547 3.8821 1.75617 4.37462C1.75617 5.34128 2.24817 6.19528 2.99617 6.69529C2.55351 6.68135 2.12059 6.56181 1.7335 6.34662V6.38128C1.73363 7.02509 1.95641 7.64903 2.36407 8.14732C2.77172 8.64562 3.33915 8.9876 3.97017 9.11529C3.55924 9.22664 3.12837 9.24306 2.71017 9.16329C2.88808 9.71744 3.23484 10.2021 3.70189 10.5494C4.16895 10.8966 4.73292 11.0891 5.31483 11.1C4.73649 11.5542 4.07428 11.8899 3.36609 12.0881C2.65789 12.2862 1.91758 12.3428 1.1875 12.2546C2.46197 13.0742 3.94556 13.5094 5.46083 13.508C10.5895 13.508 13.3942 9.25929 13.3942 5.57462C13.3942 5.45462 13.3908 5.33328 13.3855 5.21462C13.9314 4.82006 14.4026 4.3313 14.7768 3.77128L14.7762 3.77062Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconTwitter = React.memo(SvgComponent);
export default IconTwitter;
