import React from 'react';
import { Box, Spinner, VStack, Text, Button } from '@chakra-ui/react';

import Logo from 'assets/Logo';
import MotionBox from './MotionBox';

const Splash = React.memo(() => {
  return (
    <Box minH="100vh" display="flex" justifyContent="center" alignItems="center">
      <VStack spacing="6">
        <MotionBox whileHover={{ scale: 0.97 }}>
          <Logo height="8" />
        </MotionBox>
        <Spinner size="lg" />
        <VStack spacing="10">
          <Text>The server could not execute</Text>
          <MotionBox whileHover={{ translateY: '-2px' }}>
            <Button children="Retry" />
          </MotionBox>
        </VStack>
      </VStack>
    </Box>
  );
});

export default Splash;
