import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from 'theme';
import 'i18n/config';
import PageRoutes from 'pages/PageRouters';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <PageRoutes />
    </ChakraProvider>
  );
}

export default App;
