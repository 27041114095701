import React from 'react';
import { Box, Center, Flex, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import { useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import MotionBox from './MotionBox';

import Logo from 'assets/Logo';
import IconGitHud from 'assets/IconGitHud';
import IconTwitter from 'assets/IconTwitter';
import IconLinkedIn from 'assets/IconLinkedIn';
import { FeatureProps } from 'constants/types';

interface FooterProps {}

interface CategorySpec {
  title: string;
  content: FeatureProps[];
}

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  const animate = useAnimation();

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      animate.start({
        translateY: 0,
        opacity: 1,
        transition: {
          duration: 2,
        },
      });
    }
  }, [inView, animate]);

  const medias = [
    {
      icon: <IconLinkedIn />,
      onClick: () => {},
    },
    {
      icon: <IconGitHud />,
      onClick: () => {},
    },
    {
      icon: <IconTwitter />,
      onClick: () => {},
    },
  ];

  const features: CategorySpec[] = [
    {
      title: 'Product',
      content: [
        {
          title: 'How it works',
          to: 'guide',
          onClick: () => {
            navigate('/');
          },
        },
        {
          title: 'Pricing',
          to: 'pricing',
          offset: -150,
          onClick: () => {
            navigate('/');
          },
        },
        {
          title: 'Documentation',
          onClick: () => {},
        },
      ],
    },
    {
      title: 'Resources',
      content: [
        // {
        //   title: 'Blog',
        //   to: 'blog',
        //   onClick: () => {
        //     navigate('/');
        //   },
        // },
        {
          title: 'FAQ',
          onClick: () => {},
        },
        {
          title: 'Partnership',
          onClick: () => {},
        },
        {
          title: 'Press',
          onClick: () => {},
        },
      ],
    },
    {
      title: 'Contact',
      content: [
        {
          title: 'Log in',
          onClick: () => {},
        },
        {
          title: 'Linkedin',
          onClick: () => {},
        },
        {
          title: 'Twitter',
          onClick: () => {},
        },
        {
          title: 'Email',
          onClick: () => {},
        },
      ],
    },
  ];

  return (
    <Box bg="gray.50" ref={ref}>
      <MotionBox initial={{ translateY: '100%', opacity: 0 }} animate={animate}>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          maxW="8xl"
          mx="auto"
          pt={{ base: '8', lg: '16' }}
          pb={{ base: '10', lg: '20' }}
          px={{ base: '4', md: '6' }}
          borderBottomWidth="thin"
          borderBottomColor="gray.200">
          <Box flex="3" mt="2">
            <MotionBox whileHover={{ scale: 0.97 }} cursor="pointer" onClick={() => navigate('/')}>
              <Logo h={{ base: '6', md: '8' }} />
            </MotionBox>
            <HStack mt="6" spacing="4">
              {medias.map((item, index) => {
                const { icon, onClick } = item;
                return (
                  <Center
                    boxSize="8"
                    bg="blackAlpha.100"
                    _hover={{
                      bg: 'primary',
                      color: 'white',
                    }}
                    rounded="md"
                    onClick={onClick}
                    cursor="pointer"
                    key={index}
                    color="primary">
                    {icon}
                  </Center>
                );
              })}
            </HStack>
          </Box>
          <SimpleGrid flex="7" mt={{ base: '6', lg: 'unset' }} columns={{ base: 2, md: 3 }} gap="6">
            {features.map((item, index) => {
              const { title, content } = item;
              return (
                <VStack key={index} alignItems="unset" spacing="6" flex="1">
                  <Text fontSize="sm" fontWeight="bold" color="primary">
                    {title}
                  </Text>
                  <VStack alignItems="unset" spacing="4">
                    {content.map((i, idx) => {
                      const { title, to, offset, onClick } = i;
                      return (
                        <Link
                          key={idx}
                          to={to ? to : ''}
                          offset={offset}
                          spy={true}
                          smooth={true}
                          duration={500}>
                          <Box onClick={onClick} cursor="pointer" fontSize="sm" color="primary">
                            {title}
                          </Box>
                        </Link>
                      );
                    })}
                  </VStack>
                </VStack>
              );
            })}
          </SimpleGrid>
        </Flex>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          color="primary"
          fontSize="sm"
          maxW="8xl"
          mx="auto"
          pt={{ base: '6', lg: '10' }}
          pb={{ base: '8', lg: '14' }}
          px={{ base: '4', md: '6' }}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          alignItems="center">
          <Text>© 2021 Walawong Solutions Pte Ltd</Text>
          <Text ml={{ base: 'unset', md: '8' }} mt={{ base: '2', md: 'unset' }}>
            We are not a bank.
          </Text>
          <HStack spacing="8" ml={{ base: 'unset', md: '8' }} mt={{ base: '2', md: 'unset' }}>
            <Text cursor="pointer" onClick={() => navigate('/privacy-policy')}>
              Privacy
            </Text>
            <Text cursor="pointer" onClick={() => navigate('/terms-of-use')}>
              Terms and Conditions
            </Text>
          </HStack>
        </Flex>
      </MotionBox>
    </Box>
  );
};

export default Footer;
