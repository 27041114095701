const Input = {
  baseStyle: {
    field: {},
  },
  sizes: {},
  variants: {
    outline: (props: any) => ({
      field: {
        background: 'transparent',
        border: '1px solid',
        borderColor: 'gray.200',
        color: 'white',
        _hover: {
          border: '1.5px solid',
        },
        _placeholder: {
          color: 'white',
        },
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};
export { Input };
