import React from 'react';
import Icon from '@chakra-ui/icon';
import { SvgIconProps } from 'constants/types';

const SvgComponent: React.FC<SvgIconProps> = ({ size, ...props }: SvgIconProps) => {
  return (
    <Icon {...props} w={size} h={size} viewBox="0 0 16 16">
      <path
        d="M7.9987 1.33301C4.31537 1.33301 1.33203 4.31634 1.33203 7.99967C1.33128 9.39919 1.77124 10.7634 2.58951 11.8988C3.40778 13.0342 4.5628 13.8831 5.8907 14.325C6.22403 14.383 6.3487 14.183 6.3487 14.0077C6.3487 13.8497 6.34003 13.325 6.34003 12.7663C4.66537 13.075 4.23203 12.3583 4.0987 11.983C4.02337 11.791 3.6987 11.1997 3.41537 11.041C3.18203 10.9163 2.8487 10.6077 3.4067 10.5997C3.93203 10.591 4.3067 11.083 4.43203 11.283C5.03203 12.291 5.9907 12.0077 6.37337 11.833C6.43203 11.3997 6.6067 11.1083 6.7987 10.9417C5.31537 10.775 3.76537 10.1997 3.76537 7.64967C3.76537 6.92434 4.02337 6.32501 4.4487 5.85767C4.38203 5.69101 4.1487 5.00767 4.51537 4.09101C4.51537 4.09101 5.07337 3.91634 6.3487 4.77501C6.89141 4.62438 7.45214 4.54857 8.01537 4.54967C8.58203 4.54967 9.1487 4.62434 9.68203 4.77434C10.9567 3.90767 11.5154 4.09167 11.5154 4.09167C11.882 5.00834 11.6487 5.69167 11.582 5.85834C12.0067 6.32501 12.2654 6.91634 12.2654 7.64967C12.2654 10.2083 10.7074 10.775 9.22403 10.9417C9.46537 11.1497 9.67403 11.5497 9.67403 12.175C9.67403 13.0663 9.66537 13.783 9.66537 14.0083C9.66537 14.183 9.7907 14.391 10.124 14.3243C11.4474 13.8775 12.5973 13.027 13.412 11.8924C14.2266 10.7579 14.665 9.39642 14.6654 7.99967C14.6654 4.31634 11.682 1.33301 7.9987 1.33301Z"
        fill="currentColor"
      />
    </Icon>
  );
};

const IconGitHud = React.memo(SvgComponent);
export default IconGitHud;
